<template>
  <div class="post">
    <div class="post-header" ref="post_header">
      <profile-preview-dialog :profile-id="post.user.id">
        <div
            class="post-author"
            :class="{'pb-3': isRecentlyHidden}"
            @mouseover="mouseHoverProfile = true"
            @mouseleave="mouseHoverProfile = false"
        >
          <el-avatar
              :src="post.user.avatar_url"
              shape="circle"
              class="post-author-avatar"
              :class="{'cursor-pointer': !GUEST}"
          />
          <div class="post-author-info">
            <div
                class="post-author-name"
                :class="{
                'text-decoration-underline': mouseHoverProfile && !GUEST,
                'cursor-pointer': !GUEST
              }"
                v-text="post.user.full_name"
            />
            <div class="post-time" v-text="post.time_ago"/>
          </div>
        </div>
      </profile-preview-dialog>

      <button v-if="isRecentlyHidden" class="before-delete" @click="restore">
        {{ $t('post.before_post_be_hidden') }}
        <i class="icon-yes"/>
      </button>
      <actions
          v-show="!isRecentlyHidden"
          ref="actions"
          :item="post"
          :vuex-module="vuexModule"
          morphable_type="Post"
          @hidden="onHidden"
          @restored="onRestored"
      />
    </div>

    <template v-if="!isRecentlyHidden">
      <div class="post-content">
        <el-divider class="mb-3"/>
        <p v-if="post.title" class="post-content__title" :class="{'ms-2': isCompact}">{{ post.title }}</p>

        <div
            v-if="isShortQuestion"
            class="short-question font-weight-medium text-center"
            :style="{background: post.type.color}"
        >
          <dynamic-content :content="post.content"/>
        </div>

        <div v-else-if="isCompact" class="compact-content">
          <div class="content-text-wrapper" :class="{'w-100': !post.images.length}">
            <div class="content-container" ref="contentContainer">
              <div class="content" ref="content" :style="{'-webkit-line-clamp': contentContainerHeight}">
                <div ref="dynamicContent">
                  <dynamic-content :content="post.content"/>
                </div>
              </div>
            </div>
            <div v-if="showMoreActive" class="show-more">
              <a href="javascript:void(0)" @click="showFullContent">
                <span v-t="shortFilters ? 'filters.show_more' : 'filters.show_less'"/>
              </a>
            </div>
            <hash-tags :tags="tags"/>
            <optional-info :post="post"/>

            <div v-if="!xsOnly" class="post-button-wrapper pt-2">
              <copied-code-button
                  v-if="discountCodeCopied && (post.discount_code || post.discount_codes_count)"
                  :code="post.discount_code"
                  :id="post.id"
              />
              <copy-code-button
                  v-else-if="post.discount_code || post.discount_codes_count"
                  :id="post.id"
                  :code="post.discount_code"
                  :url="post.discount_url"
              />
              <go-webshop-button v-else :url="webshopUrl"/>
            </div>
          </div>
          <div v-if="post.images.length" class="compact-carousel">
            <promo-image-carousel
                ref="post_carousel"
                :images="post.images"
                :height="175"
                :width="carouselWidth"
            />
          </div>
          <div v-if="xsOnly" class="post-button-wrapper p-2">
            <copied-code-button
                v-if="discountCodeCopied && (post.discount_code || post.discount_codes_count)"
                :code="post.discount_code"
                :id="post.id"
            />
            <copy-code-button
                v-else-if="post.discount_code || post.discount_codes_count"
                :id="post.id"
                :code="post.discount_code"
                :url="post.discount_url"
            />
            <go-webshop-button v-else :url="webshopUrl"/>
          </div>
        </div>

        <div v-else class="content-container" ref="contentContainer">
          <div class="content" ref="content" :style="{'-webkit-line-clamp': contentContainerHeight}">
            <dynamic-content :content="post.content"/>
          </div>
        </div>
        <a href="javascript:void(0)" v-if="showMoreActive && !isCompact" class="show-more" @click="showFullContent">
          <span v-t="shortFilters ? 'filters.show_more' : 'filters.show_less'"/>
        </a>

        <template v-if="!isCompact">
          <p v-if="post.started_at" class="valid-dates mb-1">
            {{ $t('post.started_at') }}: {{ dateStringFromISO(post.started_at.iso, 'd-m-Y') }}
          </p>
          <p v-if="post.expired_at" class="valid-dates my-1">
            {{ $t('post.expired_at') }}: {{ dateStringFromISO(post.expired_at.iso, 'd-m-Y') }}
          </p>

          <hash-tags :tags="tags"/>
          <optional-info :post="post"/>

          <div v-if="hasImages" class="carousel-container" :style="carouselHeight">
            <div class="promo-image-carousel">
              <promo-image-carousel
                  ref="post_carousel"
                  :images="post.images"
                  :height="carouselHeight"
                  :width="carouselWidth"
              />
            </div>
          </div>
        </template>
      </div>

      <reactions
          class="px-3.25"
          :rating="post.rating_calculated"
          :is-like="post.is_hit"
          :comments-count="commentsCount"
          @react="$refs.commentForm.focusTextarea()"
          @hit="processHit"
          @share="processShare"
      />

      <div class="post-footer">
        <div class="comments-wrapper">
          <comments-header
              :hidden-total="hiddenComments"
              :total="commentsCount"
              :total-visible="false"
              @show-more="showMoreComments"
          />

          <comments-loading :loading="commentsLoading"/>

          <comments :comments-data="visibleCommentsData" @hit="hitCommentProcess"/>

          <comments-loading :loading="commentsPushing"/>

          <comment-form ref="commentForm" @submit="pushComment"/>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import {slug} from '@/mixins/slug';
import {needAuth} from '@/mixins/auth/needAuth';
import {actions} from '@/mixins/post/actions';
import {comments} from '@/mixins/comments';
import router from '@/router';
import {share} from '@/mixins/post/share';
import {mapGetters} from 'vuex';
import {dates} from '@/mixins/dates';

import Actions from '@/components/feed/post/Actions.vue';
import Reactions from '@/components/feed/Reactions.vue';
import CommentForm from '@/components/feed/comment/CommentForm';
import Comments from '@/components/feed/comment/Comments';
import CommentsHeader from '@/components/feed/comment/CommentsHeader';
import CommentsLoading from '@/components/feed/comment/CommentsLoading';
import HashTags from '@/components/feed/post/HashTags';
import DynamicContent from '@/components/feed/post/DynamicContent';
import CopyCodeButton from '@/components/feed/buttons/CopyCodeButton.vue';
import GoWebshopButton from '@/components/feed/buttons/GoWebshopButton';
import ProfilePreviewDialog from '@/components/feed/profile/ProfilePreviewDialog';
import CopiedCodeButton from '@/components/feed/buttons/CopiedCodeButton';
import OptionalInfo from '@/components/feed/post/OptionlInfo.vue';
import PromoImageCarousel from '@/components/feed/promo/PromoImageCarousel';

export default {
  name: 'post',
  components: {
    PromoImageCarousel,
    OptionalInfo,
    ProfilePreviewDialog,
    CopiedCodeButton,
    GoWebshopButton,
    CopyCodeButton,
    Actions,
    DynamicContent,
    HashTags,
    CommentsLoading,
    CommentsHeader,
    Comments,
    CommentForm,
    Reactions
  },
  props: {
    post: Object,
    vuexModule: {
      type: String,
      default: 'promo',
    },
    discountCodeCopied: {
      type: Boolean,
      default: false
    }
  },
  mixins: [comments, slug, needAuth, actions, share, dates],
  data() {
    return {
      carouselHeight: 0,
      carouselWidth: 0,
      contentContainerHeight: 'initial',
      isRecentlyHidden: false,
      showMoreActive: false,
      mouseHoverProfile: false,
      shortFilters: true,
    }
  },
  computed: {
    ...mapGetters('auth', ['GUEST']),
    tags() {
      const result = [];
      if (_.get(this.post, 'type.id', false)) {
        result.push({
          text: this.convertToSlug(_.get(this.post, 'type.translation.alias')),
          link: this.$router.resolve({name: 'home'}).href,
          entityType: 'type'
        });
      }

      if (_.get(this.post, 'webshops[0].id', false)) {
        result.push({
          text: this.convertToSlug(_.get(this.post, 'webshops[0].translation.title')),
          link: this.$router.resolve({
            name: 'webshop.show',
            params: {webshopSlug: _.get(this.post, 'webshops[0].translation.route')}
          }).href,
          entityType: 'webshop'
        });
      }

      if (_.get(this.post, 'stores[0].id', false)) {
        result.push({
          text: this.convertToSlug(_.get(this.post, 'stores[0].translation.title')),
          link: this.$router.resolve({
            name: 'store.show',
            params: {storeSlug: _.get(this.post, 'stores[0].translation.route')}
          }).href,
          entityType: 'store'
        });
      }

      if (_.get(this.post, 'brands[0].id', false)) {
        result.push({
          text: this.convertToSlug(_.get(this.post, 'brands[0].translation.title')),
          link: this.$router.resolve({
            name: 'brand.show',
            params: {brandSlug: _.get(this.post, 'brands[0].translation.route')}
          }).href,
          entityType: 'brand'
        });
      }

      if (_.get(this.post, 'products[0].id', false)) {
        result.push({
          text: this.convertToSlug(_.get(this.post, 'products[0].translation.title')),
          link: this.$router.resolve({
            name: 'product.show',
            params: {productSlug: _.get(this.post, 'products[0].translation.route')}
          }).href,
          entityType: 'product'
        });
      }

      return result;
    },
    /**
     * Is post coupon
     * @returns {boolean}
     */
    isCompact() {
      return this.post.type.kind === this.$consts.KIND.DISCOUNT || this.isWebShopRoute
    },
    /**
     * isWebShopRoute
     * @returns {boolean}
     */
    isWebShopRoute() {
      return router.currentRoute.value.name === 'webshop.show';
    },
    /**
     * Is short question
     * @returns {boolean}
     */
    isShortQuestion() {
      return this.post.type.kind === this.$consts.KIND.QUESTION && !this.post.images.length && this.post.content_length <= 115;
    },
    /**
     * Webshop Url
     * @returns {*|null}
     */
    webshopUrl() {
      if (this.post.url !== null) {
        return this.post.url;
      }
      return _.get(this.post, 'webshops[0].translation.affiliate_url', null);
    },
    /**
     * Is post have images
     * @returns {*}
     */
    hasImages() {
      return !_.isEmpty(this.post.images);
    },
    /**
     * Data for mixin
     * @returns {{morphable_type: string, entity: Object}}
     */
    entityData() {
      return {
        entity: this.post,
        morphable_type: 'Post'
      }
    }
  },
  mounted() {
    if (this.isRecentlyHidden) {
      return;
    }
    new ResizeObserver(() => this.calculateHeight()).observe(this.$refs.post_header);

    this.commentsCount = this.post.comments_count;
    this.comments = this.post.comments;
    this.visibleComments = _.min([this.xsOnly ? 0 : 2, this.post.comments.length]);

    if (this.$refs.content && this.$refs.contentContainer) {
      const thisElem = this;

      new ResizeObserver(function () {
        if (thisElem.$refs.content && thisElem.$refs.contentContainer &&
            thisElem.contentContainerHeight === 'initial' && thisElem.shortFilters) {

          const showMoreActive = (thisElem.$refs.contentContainer.clientHeight / 15) > 6.6;
          // const showMoreActive = (thisElem.$refs.content.clientHeight - thisElem.$refs.contentContainer.clientHeight) > 5;

          if (thisElem.isCompact) {
            if (!showMoreActive) {
              thisElem.showMoreActive = false;
            } else {
              thisElem.contentContainerHeight = 4;
              thisElem.showMoreActive = true;
            }
          } else {
            thisElem.showMoreActive = showMoreActive;
            if (showMoreActive) {
              thisElem.contentContainerHeight = 5;
            }
          }
        }
      }).observe(this.$refs.content);
    }
  },
  methods: {
    calculateHeight() {
      if (!this.$refs.post_header) {
        return 0;
      }

      this.carouselWidth = this.$refs.post_header.clientWidth - 20;
      this.carouselHeight = 0;

      if (!this.hasImages) {
        return 0;
      }

      const thisElem = this;

      _.forEach(thisElem.post.images, function (image) {
        const currentImage = new Image();
        currentImage.src = image.src;
        currentImage.onload = function () {
          let currentHeight = 0;
          if (thisElem.carouselWidth < currentImage.width) {
            currentHeight = thisElem.carouselWidth / currentImage.width * currentImage.height;
          } else {
            currentHeight = currentImage.height;
          }
          if (thisElem.carouselHeight < currentHeight) {
            thisElem.carouselHeight = _.min([currentHeight, 500]);
          }
        };
      });
    },
    processShare(destination) {
      const link = new URL(
          this.$router.resolve({
            name: 'posts.show',
            params: {id: this.post.id}
          }).href,
          window.location.origin
      ).href;
      this.shareLink(link, destination);
    },
    showMoreComments() {
      this.showMoreCommentsProcess('Post', this.post.id);
    },
    pushComment(form) {
      this.pushCommentProcess('Post', this.post.id, form);
    },
    showFullContent() {
      if (this.GUEST) {
        this.emitter.emit('show-login-dialog', 'register');
      } else {
        this.shortFilters = !this.shortFilters;
        if (this.shortFilters) {
          if (this.isCompact) {
            this.contentContainerHeight = 4;
          } else {
            this.contentContainerHeight = 5;
          }
        } else {
          this.contentContainerHeight = 'initial';
        }
      }
    },
    onHidden() {
      this.isRecentlyHidden = true
      this.$emit('hidden')
    },
    onRestored() {
      this.isRecentlyHidden = false
      this.$emit('restored')
    },
    restore() {
      this.$refs.actions.restore()
    }
  }
}
</script>
